import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

// Components
const MainDashboard = Loader(
  lazy(() => import('src/content/Home/MainDashboard'))
);
const UserDashboard = Loader(
  lazy(() => import('src/content/Home/UserDashboard'))
);

const homeRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />,
  },
  {
    path: 'dashboard',
    element: <MainDashboard />,
  },
  {
    path: 'user-dashboard',
    element: <UserDashboard />,
  },
];

export default homeRoutes;
