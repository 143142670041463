import type { ReactNode } from 'react';
import {
  Dashboard as DashboardIcon,
  Home as HomeIcon,
} from '@mui/icons-material';

import { UserRoleCode } from 'src/utils/types';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
  roles?: UserRoleCode[];
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  roles?: UserRoleCode[];
}

const menuItems: MenuItems[] = [
  {
    heading: 'Administración',
    items: [
      {
        name: 'Inicio',
        icon: HomeIcon,
        link: '/home/dashboard',
        roles: ['AD', 'WT'],
      },
      {
        name: 'Tablero',
        icon: DashboardIcon,
        link: '/home/user-dashboard?tab=active',
      },
    ],
  },
  {
    heading: 'Reportes',
    roles: ['AD', 'WT'],
    items: [
      {
        name: 'Tickets',
        link: '/reports/tickets',
      },
    ],
  },
];

export default menuItems;
